import api from '@/api';

const state = {
  reservations: [],
  streetAdress:null,
  eligibilty:false
,
  currentCheckoutStripeClientSecret:
    sessionStorage.getItem('checkoutStripeClientSecret') || null
};

const mutations = {
  setReservations(state, reservations) {
    state.reservations = reservations;
  },
  setEligibility(state,payload){
    console.log('setEligibility',payload)
    state.eligibilty = payload
  },
  streetAdressMutation(state,payload){
    state.streetAdress = payload
  },
  setCurrentCheckoutStripeClientSecret(state, secret) {
    state.currentCheckoutStripeClientSecret = secret;
    sessionStorage.setItem('checkoutStripeClientSecret', secret);
  },
  clearCurrentCheckoutStripeClientSecret(state) {
    state.currentCheckoutStripeClientSecret = null;
    sessionStorage.removeItem('checkoutStripeClientSecret');
  }
};

const actions = {
  async getReservations({ commit }) {
    await api.reservation.getReservations().then((response) => {
      commit('setReservations', response);
    });
  },
  async getEligibilty({ commit },platformId) {
    console.log("aaa",platformId)
    await api.reservation.getEligibilty({platformId}).then((response) => {
      commit('setEligibility', response);
      return response
    });
  },
  async createPayment({ dispatch, commit }, { platformId }) {
    try {
      const response = await api.payment.createPayment({
        platform_id: platformId
      });

      commit('clearCurrentCheckoutStripeClientSecret');
      commit('setCurrentCheckoutStripeClientSecret', response.stripe);

      dispatch('getReservations');
      return response;
    } catch (error) {
      console.error('Failed to fetch payment data:', error);
    }
  },
  async createReservation({ dispatch }, {
    vehicleVin,
    pickupDate,
    pickupTime,
    returnDate,
    returnTime,
    addonIds,
  }) {
    const response = await api.reservation.createReservation({
      vehicle_vin: vehicleVin,
      pickup_date: pickupDate,
      pickup_time: pickupTime,
      return_date: returnDate,
      return_time: returnTime,
      addon_ids: addonIds,
    });

    dispatch('getReservations');
    return response;
  },
  async applyDiscount({ commit }, { code, platformId }) {
    const response = await api.discounts.applyDiscount({
      code,
      platformId
    });

    commit('clearCurrentCheckoutStripeClientSecret');
    commit('setCurrentCheckoutStripeClientSecret', response.stripe);
  },
  async addAddon({ commit }, { platformId, addonIds }) {
    const response = await api.reservation.applyAddon(platformId, addonIds);

    commit('clearCurrentCheckoutStripeClientSecret');
    commit('setCurrentCheckoutStripeClientSecret', response.stripe);
  },
  async confirmReservation({ commit }) {
    commit('clearCurrentCheckoutStripeClientSecret');
  },
  async createRentalAgreement({ dispatch }, agreement) {
    await api.agreements.createAgreement({
      type: 'RENTAL_AGREEMENT',
      platform_id: agreement.platform_id,
      signature: agreement.signature,
      date: agreement.date
    });
    dispatch('getReservations');
  },
  async createDamageWaiverAgreement({ dispatch }, agreement) {
    console.log(agreement);
    await api.agreements.createAgreement({
      type: 'DAMAGE_WAIVER',
      platform_id: agreement.platform_id,
      signature: agreement.signature,
      date: agreement.date
    });
    dispatch('getReservations');
  }
};

const getters = {
  getStreetAdress:(state) =>{
    return state.streetAdress
  },
  upcomingReservations: (state) => {
    return state.reservations.filter((reservation) => {
      return new Date(reservation.pickup_at) >= new Date();
    });
  },
  pastReservations: (state) => {
    return state.reservations.filter((reservation) => {
      return new Date(reservation.return_at) < new Date();
    });
  },
  reservations: (state) => state.reservations,
  getEligibiltyValue: (state) => state.eligibilty,
  currentReservation: (state) => {
    return state.reservations.find((reservation) => {
      return (
        new Date(reservation.return_at) > new Date() &&
        new Date(reservation.pickup_at) < new Date()
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
