<template>
  <div class="flex z-40 flex-col w-full mx-auto max-w-5xl px-6 py-16 md:py-24 gap-y-8 md:gap-y-6">
    <template v-if="pageLoading">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div class="flex flex-col gap-10">
          <div>
            <SkeletonLoader width="200px" height="16px" rounded border-radius="4px" />
            <SkeletonLoader width="140px" height="14px" rounded border-radius="4px" class="mt-4" />
          </div>
          <div>
            <div class="flex gap-4">
              <SkeletonLoader width="100%" height="64px" rounded border-radius="4px" />
              <SkeletonLoader width="100%" height="64px" rounded border-radius="4px" />
            </div>
            <div>
              <SkeletonLoader width="100%" height="64px" rounded border-radius="4px" class="mt-4" />
            </div>


          </div>
          <div class="flex gap-2.5 mt-4">
            <SkeletonLoader width="100%" height="64px" rounded border-radius="4px" />
            <SkeletonLoader width="120px" height="64px" rounded border-radius="4px" />
          </div>
          <SkeletonLoader width="100%" height="264px" rounded border-radius="4px" class="mt-4" />
          <div class="flex justify-between">
            <SkeletonLoader width="120px" height="64px" rounded border-radius="4px" />
            <SkeletonLoader width="120px" height="64px" rounded border-radius="4px" />
          </div>
        </div>
        <div class="flex flex-col gap-5">
          <SkeletonLoader width="100%" height="395px" rounded border-radius="4px" />
          <SkeletonLoader width="100%" height="288px" rounded border-radius="4px" />
        </div>
      </div>
    </template>
    <div v-if="reservation && !pageLoading" class="grid grid-cols-1 md:grid-cols-2 gap-10 items-start">
      <!-- Left Column: Review & Pay -->
      <div class="flex flex-col gap-10" v-if="hasOutstandingBalance">
        <div>
          <h1 class="text-heading font-medium tracking-negative-3 text-primary-100">
            Review & Pay
          </h1>
          <p class="mt-2 text-xl leading-8 text-primary-500 font-urbanist">
            Confirm your trip details are correct
          </p>
        </div>
        <template v-if="isPaymentElementMounted">
          <div v-if="!isNameEmailFormComplete" class="flex flex-col">
            <NameEmailForm v-model="nameEmailFormData" :disabled="isNameEmailFormDisabled"
              :userNameDisabled="userNameDisabled" :userLastNameDisabled="userLastNameDisabled" />


          </div>
          <SmartyAutocomplete @change="handleUpdateUserAddress" @input="(value) => isAddressValid = value && value.trim().length > 0" :default-address="userAddress" />


          <CouponField :platform-id="reservation.id" @applied-discount="handleCouponApplied" />
        </template>
        <div>
          <!-- <StripeForm :stripe-client-secret="currentCheckoutStripeClientSecret" @payment-confirmed="confirmBooking" /> -->
          <div id="payment-element">
            <!-- Stripe Elements will be inserted here. -->
          </div>
          <template v-if="!isPaymentElementMounted">
            <div class="min-h-[380px] flex items-center justify-center">
              <SkeletonLoader width="100%" height="64px" rounded border-radius="4px"/>
            </div>
          </template>
        </div>


        <FreeCancellationCard :cancellation-date="cancellationDate" />


        <div class="hidden md:flex justify-between items-start gap-x-4 w-full">
          <CtaButton class="w-full md:w-max" variant="secondary" leading-icon @click="goBack">Back</CtaButton>
          <CtaButton class="w-full md:w-max min-w-28" :is-disabled="stripeLoading ||
            !isStripeFormComplete ||
            !isPaymentElementMounted ||
            !isNameEmailFormComplete ||
            !isAddressComplete
            " :is-loading="stripeLoading || loading" @click="handleSubmit">
            Confirm Booking
          </CtaButton>
        </div>
      </div>
      <div class="mb-4" v-else>
        <NoOutstandingBalance />
      </div>
      <!-- Right Column: Line Items -->
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-5 p-6 bg-primary-900 border border-primary-800 rounded-lg">
          <div class="flex items-center gap-6">
            <img :src="getMoneyshotImage(reservation.vehicle.images)" alt="Car Image"
              class="size-20 object-cover rounded" />
            <div class="flex flex-col justify-between md:flex-row w-full">
              <h3 class="text-2xl tracking-negative-2 text-primary-300 font-medium leading-9">
                {{ reservation.vehicle.model.name }}
              </h3>
              <div class="flex gap-2 items-center">
                <p class="text-base font-medium text-primary-300">
                  ${{ reservation.payment_balance / 100 }}
                </p>
                <Badge size="small">${{ reservation.vehicle.rack_rate / 100 }}/day</Badge>
              </div>
            </div>
          </div>
          <div class="flex flex-col border border-primary-700 rounded-lg overflow-hidden">
            <TextInput id="location" label="Pickup and return at" icon class="border-b border-primary-700" readonly
              :value="reservation.pickup_location.name">
              <template #icon>
                <MapPinIcon class="size-5" />
              </template>
            </TextInput>
            <TextInput id="pickupDate" label="Pickup Date" icon class="border-b border-primary-700" readonly
              :value="formatDate(reservation.pickup_at)">
            </TextInput>
            <TextInput id="returnDate" label="Return Date" icon readonly :value="formatDate(reservation.return_at)">
            </TextInput>
          </div>
          <ReservationLineItems v-if="hasOutstandingBalance" :reservation="reservation" :statuses="['Pending']"
            :key="lineItemsKey" />
        </div>
        <div class="flex flex-col gap-5 p-6 bg-primary-900 border border-primary-800 rounded-lg">
          <div class="flex items-center gap-3">
            <UserCircleIcon class="size-7"></UserCircleIcon>
            <h3 class="text-2xl tracking-negative-2 text-primary-300 font-medium leading-9">
              {{ fullName }}
            </h3>
          </div>
          <div class="flex flex-col border border-primary-700 rounded-lg overflow-hidden">
            <TextInput id="number" label="Phone Number" icon class="border-b border-primary-700" readonly
              :value="phoneNumber">
              <template #icon>
                <PhoneIcon class="size-5"></PhoneIcon>
              </template>
            </TextInput>
            <TextInput id="license" label="Driver’s License" :class="{ 'border-b border-primary-700': userEmail }" icon
              readonly :value="licenseNumber">
              <template #icon>
                <CreditCardIcon class="size-5"></CreditCardIcon>
              </template>
            </TextInput>
            <TextInput v-show="userEmail" id="email" label="Email" icon readonly :value="userEmail">
              <template #icon>
                <UserIcon class="size-5"></UserIcon>
              </template>
            </TextInput>
          </div>
        </div>
      </div>
      <!--Mobile Navigation-->
      <div class="flex md:hidden justify-between items-start gap-x-4 w-full">
        <CtaButton class="w-full md:w-max" variant="secondary" leading-icon @click="goBack">Back</CtaButton>
        <CtaButton class="w-full md:w-max min-w-28" :is-disabled="stripeLoading ||
          !isStripeFormComplete ||
          !isPaymentElementMounted ||
          !isNameEmailFormComplete ||
          !isAddressComplete
          " :is-loading="stripeLoading || loading" @click="handleSubmit">
          Confirm Booking
        </CtaButton>
      </div>
    </div>
    <DisqualifiedRenterModal :value="checkEligibilty" @input="() => checkEligibilty = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import FreeCancellationCard from '@/components/cards/FreeCancellationCard.vue';
import ReservationLineItems from '@/components/cards/ReservationLineItems.vue';
import MapPinIcon from '@/components/core/icons/MapPinIcon.vue';
import TextInput from '@/components/core/TextInput.vue';
import CtaButton from '@/components/core/CtaButton.vue';
import Badge from '@/components/core/Badge.vue';
import getStripe from '@/utils/stripe';
import NameEmailForm from '@/components/payment/NameEmailForm.vue';
import CouponField from '@/components/payment/CouponField.vue';
import UserCircleIcon from '@/components/core/icons/UserCircleIcon.vue';
import PhoneIcon from '@/components/core/icons/PhoneIcon.vue';
import CreditCardIcon from '@/components/core/icons/CreditCardIcon.vue';
import UserIcon from '@/components/core/icons/UserIcon.vue';
import api from '@/api';
import NoOutstandingBalance from '@/components/payment/NoOutstandingBalance.vue';
import SkeletonLoader from '@/components/core/animations/SkeletonLoader.vue';
import SmartyAutocomplete from "@/components/smarty/SmartyAutocomplete.vue";
import DisqualifiedRenterModal from '@/components/payment/DisqualifiedRenterModal.vue';


export default {
  name: 'TripPaymentView',
  components: {
    MapPinIcon,
    ReservationLineItems,
    FreeCancellationCard,
    TextInput,
    CtaButton,
    Badge,
    NameEmailForm,
    CouponField,
    UserCircleIcon,
    PhoneIcon,
    CreditCardIcon,
    UserIcon,
    NoOutstandingBalance,
    SkeletonLoader,
    SmartyAutocomplete,
    DisqualifiedRenterModal
  },
  data() {
    return {
      checkEligibilty: false,
      pageLoading: false,
      stripeLoading: false,
      loading: false,
      isPaymentElementMounted: false,
      stripe: null,
      elements: null,
      isStripeFormComplete: false,
      nameEmailFormData: {
        firstName: '',
        lastName: '',
        email: ''
      },
      streetAdress: '',
      isNameEmailFormDisabled: false,
      isAddressValid:false,
      lineItemsKey: 0,
      hasOutstandingBalance: true
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('reservations', ['currentCheckoutStripeClientSecret']),
    ...mapGetters('reservations', ['reservations', 'getEligibiltyValue']),
    reservation() {
      return this.reservations.find(
        (reservation) => reservation.id === this.$route.params.platform_id
      );
    },
    cancellationDate() {
      const date = new Date(this.reservation.pickup_at);
      date.setDate(date.getDate() - 1);
      return date;
    },
    fullName() {
      if (!this.user) return '';
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    userName() {
      if (!this.user) return '';
      return this.user.first_name ? this.user.first_name : '';
    },
    userLastName() {
      if (!this.user) return '';
      return this.user.last_name ? this.user.last_name : '';
    },
    userNameDisabled() {
      if (!this.user) return false;
      return this.user.first_name ? true : false;
    },
    userLastNameDisabled() {
      if (!this.user) return false;
      return this.user.last_name ? true : false;
    },
    phoneNumber() {
      if (!this.user) return '';
      return this.user.phone;
    },
    licenseNumber() {
      if (!this.user) return '';
      return this.user.license_number;
    },
    userEmail() {
      if (!this.user) return null;
      return this.user.email;
    },
    userAddress() {
      if (!this.user || !this.user.address_line1) {
        return '';
      }


      const {
        address_line1,
        address_line2,
        city,
        state,
        zip_code
      } = this.user;

      const streetPart = [address_line1, address_line2, city]
        .filter(Boolean)
        .join(' ');

      const regionPart = [state, zip_code]
        .filter(Boolean)
        .join(' ');

      return [streetPart, regionPart].filter(Boolean).join(', ');
    },
    isNameEmailFormComplete() {
      return (
        !!this.user.first_name &&
        !!this.user.last_name &&
        !!this.user.email
      );
    },
    isAddressComplete() {
      return !!this.userAddress && this.isAddressValid;
    },
  },
  methods: {
    ...mapActions(
      'reservations',
      ['createPayment', 'getReservations', 'confirmReservation', 'getEligibilty']
    ),
    ...mapActions('auth', ['updateProfile']),
    goBack() {
      // Navigate back
      this.$router.go(-1);
    },
    handleUpdateUserAddress(value) {
      const {
        city,
        secondary,
        state,
        street_line,
        zipcode
      } = value;
      this.updateProfile({
        address_line1: street_line,
        address_line2: secondary || '',
        city,
        state,
        zip_code: zipcode,
        country: 'us'
      });
    },
    prefillNameEmailForm() {
      if (this.user) {
        this.nameEmailFormData.firstName = this.user.first_name;
        this.nameEmailFormData.lastName = this.user.last_name;
        this.nameEmailFormData.email = this.user.email;
      }
      if (this.isNameEmailFormComplete) {
        this.isNameEmailFormDisabled = true;
      }
    },
    async handleCouponApplied() {
      await this.getReservations();
      await this.loadStripeElement();
      this.refreshLineItems();
    },
    refreshLineItems() {
      this.lineItemsKey += 1
    },
    confirmBooking() {
      this.confirmReservation();
      this.$router.push({
        name: 'confirmation',
        params: { platform_id: this.$route.params.platform_id }
      });
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')
          ?.image_url;
    },
    async loadStripeElement() {
      if (this.currentCheckoutStripeClientSecret === null) {
        this.$router.push({
          name: 'trip',
          params: { platform_id: this.$route.params.platform_id }
        });
      } else {
        this.stripe = await getStripe();
        this.elements = this.stripe.elements({
          clientSecret: this.currentCheckoutStripeClientSecret
        });

        const paymentElement = this.elements.create('payment');
        paymentElement.mount('#payment-element');
        this.isPaymentElementMounted = true;
        paymentElement.on('change', (event) => {
          this.isStripeFormComplete = event.complete;
        });
      }
    },
    async handleSubmitNew() {
      if (
        this.stripeLoading ||
        !this.isStripeFormComplete ||
        !this.isPaymentElementMounted
      ) {
        return;
      }
      this.stripeLoading = true;

      const initialReservation = this.reservation.transactions.filter(transaction => transaction.category === 'Rack');

      if (initialReservation.length === 1 && initialReservation[0].status === 'Pending') {
        const conflictExists = await api.reservation.checkConflicts({
          vehicleVin: this.reservation.vehicle.vin,
          platformId: this.reservation.id,
        })

        if (conflictExists) {
          this.$router.push({
            path: `vehicle/${this.reservation.vehicle.vin}`,
            query: {
              vehicleUnavailableModal: true,
            },
          });
        }
      }

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: 'if_required'
      });

      if (error) {
        this.error = error.message;
      } else {
        this.error = null;
        this.confirmBooking();
      }
      this.stripeLoading = false;
    },
    async handleSubmit() {
      return this.checkEligibility();
    },
    async checkEligibility() {
      this.loading = true;
      try {
        const response = await this.getEligibilty({
          platform_id: this.$route.params.platform_id,
        })
        console.log("this.$route.params.platform_id", response)

        this.eligible = this.getEligibiltyValue.is_eligible;

        // If not eligible, show the modal
        if (!this.eligible) {
          this.showEligibilityModal();
        } else {
          this.handleSubmitNew()
        }
      } catch (error) {
        console.error('Error checking eligibility', error);
      } finally {
        this.loading = false;
      }
    },
    showEligibilityModal() {
      // Logic to show modal (you can customize this)
      this.checkEligibilty = true
    },
  },
  async created() {
    try {
      this.checkEligibilty = false
      this.pageLoading = true;
      await this.getReservations();
      this.hasOutstandingBalance = this.reservation.transactions
        .filter((transaction) => transaction.category !== 'Deposit')
        .some(
          (transaction) => transaction.status === 'Pending'
        );
      if (this.hasOutstandingBalance) {
        await this.createPayment({ platformId: this.$route.params.platform_id });
        await this.getReservations();
        this.refreshLineItems();
        this.prefillNameEmailForm();
        this.pageLoading = false;
        await this.loadStripeElement();
      } else {
        this.pageLoading = false;
      }
      this.nameEmailFormData.firstName = this.userName
      this.nameEmailFormData.lastName = this.userLastName

    } catch (error) {
      console.error("error during payment page setup:", error);
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
