<template>
  <div class="w-full">
    <!-- First and Last Name Row -->
    <div class="flex space-x-4 mb-4">
      <TextField id="first_name" v-model="formData.firstName" :disabled="userNameDisabled || disabled" label="First Name" :class="{ '!bg-[#D6D2CD] text-gray-[#54504C]': userNameDisabled || disabled }"
        is-rounded is-border  :is-disabled="userNameDisabled" />
      <TextField id="last_name" v-model="formData.lastName" :disabled="userLastNameDisabled || disabled" label="Last Name" :class="{ '!bg-[#D6D2CD] text-gray-[#54504C]': userLastNameDisabled || disabled}"
         is-rounded is-border :is-disabled="userNameDisabled || disabled" />
    </div>

    <!-- Email Row -->
    <div class="mb-4">
      <TextField id="email" v-model="formData.email" label="Email"
         :class="{ '!bg-[#D6D2CD] text-gray-[#54504C]': disabled }" is-rounded
        is-border :is-disabled="disabled" />
    </div>
  </div>
</template>

<script>
import TextField from '../core/TextInput.vue';

export default {
  name: 'NameEmailForm',
  components: {
    TextField,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        firstName: '',
        lastName: '',
        email: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userNameDisabled:{
      type: Boolean,
      default: false,
    },
    userLastNameDisabled:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
