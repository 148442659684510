<template>
  <div>
    <div class="bg-primary-900 flex flex-col gap-5 p-6 rounded-xl border border-primary-800">
      <div class="flex items-center justify-between">
        <p class="text-heading tracking-negative-3 font-medium text-primary-100">
          ${{ vehicle.rack_rate / 100
          }}<span class="text-base leading-6.5">/day</span>
        </p>
        <div class="flex items-center">
          <GreenTickIcon class="size-4"></GreenTickIcon>
          <p class="ml-2 mr-3 text-primary-200 text-sm font-urbanist">
            <span v-if="isUnlimitedMilesSelected">unlimited miles</span>
            <span v-else>{{ milesIncluded }} miles included</span>
          </p>
          <div class="cursor-pointer" v-tooltip="{
            content: `Here’s how we calculate miles per day. <a class='underline' href='${landingBaseURL}/blog/how-does-mileage-work-when-renting-a-tesla-with-resla' target='_blank'>Learn more</a>`,
            maxWidth: 200,
            placement: 'top',
            interactive: true
          }">
            <InfoIcon class="size-5"></InfoIcon>
          </div>
        </div>
      </div>

      <div class="border border-primary-800 rounded-lg">
        <DatepickerModal id="booking-card-date-selection" class="w-full" label="Booking Dates" rounded :date-value="{
          startDate: filters.pickupDate,
          endDate: filters.returnDate,
        }" :time-value="{
            startTime: filters.pickupTime,
            endTime: filters.returnTime,
          }" :disabled-dates="vehicleUnavailableDates" :max-date="maxPickupDate" @apply="handleBookingDateTime" />
      </div>

      <div class="flex flex-col space-y-4">
        <div class="flex justify-between font-urbanist text-primary-100 text-base leading-6.5 font-normal">
          <span>${{ vehicle.rack_rate / 100 }} x
            {{ getRackPeriodLabel(rentalDays) }}
          </span>
          <span>${{ totalRentalPrice / 100 }}</span>
        </div>

        <!-- Dynamically list selected addons -->
        <div v-for="addon in selectedAddonItems" :key="addon.id"
          class="flex justify-between font-urbanist text-primary-100 text-base leading-6.5 font-normal">
          <span>{{ formatLabel(addon.description) }} x
            {{ getAddonPeriodLabel(rentalDays) }}
          </span>
          <span>${{ (addon.amount * rentalDays) / 100 }}</span>
        </div>

        <div class="w-full h-px bg-primary-700"></div>
        <div class="flex justify-between font-urbanist text-primary-100 text-base leading-6.5 font-medium">
          <span>Subtotal</span>
          <span>${{ totalPrice / 100 }}</span>
        </div>
      </div>

      <AvailableAddons v-if="availableAddons.length > 0" :availableAddons="availableAddons"
        @update:addons="updateSelectedAddons" />
      <template v-else>
        <div class="flex justify-between items-center mb-4 rounded-lg">
          <SkeletonLoader width="100%" height="57px" rounded border-radius="4px" animation="wave" />
        </div>
        <div class="flex justify-between items-center mb-4 rounded-lg">
          <SkeletonLoader width="100%" height="57px" rounded border-radius="4px" animation="wave" />
        </div>
      </template>

      <CtaButton class="w-full" @click="bookNow" :is-disabled="isBookingLoading" :is-loading="isBookingLoading">
        Book Now
      </CtaButton>
    </div>
    <FreeCancellationCard v-if="moreThan24HoursUntilPickup" class="mt-5" :cancellationDate="cancellationDate" />
  </div>
</template>

<script>
import api from '@/api';
import { mapActions, mapState } from 'vuex';
import DatepickerModal from '../core/DatepickerModal.vue';
import FreeCancellationCard from '../cards/FreeCancellationCard.vue';
import AvailableAddons from '../cards/AvailableAddons.vue';
import CtaButton from '../core/CtaButton.vue';
import GreenTickIcon from '../core/icons/GreenTickIcon.vue';
import InfoIcon from '../core/icons/InfoIcon.vue';
import { formatDateToYYYYMMDD } from '@/helpers/util';
import SkeletonLoader from '../core/animations/SkeletonLoader.vue';

export default {
  name: 'BookingCard',
  components: {
    DatepickerModal,
    FreeCancellationCard,
    AvailableAddons,
    CtaButton,
    GreenTickIcon,
    InfoIcon,
    SkeletonLoader
  },
  data() {
    return {
      collisionProtection: false,
      availableAddons: [],
      selectedAddons: [],
      landingBaseURL: process.env.VUE_APP_LANDING_ROOT_URL,
    };
  },
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    isBookingLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('search', ['filters']),
    rentalDays() {
      const pickup = new Date(this.filters.pickupDate);
      const returnD = new Date(this.filters.returnDate);
      const diffTime = Math.abs(returnD - pickup);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    totalRentalPrice() {
      return this.vehicle.rack_rate * this.rentalDays;
    },
    totalAddonsPrice() {
      return this.selectedAddonItems.reduce((acc, addon) => {
        return acc + addon.amount * this.rentalDays;
      }, 0);
    },
    selectedAddonItems() {
      return this.availableAddons.filter((addon) =>
        this.selectedAddons.includes(addon.id)
      );
    },
    redirectToDamageWaiver() {
      const damageWaiverAvailable = this.availableAddons.find(
        (addon) => addon.type === 'DAMAGE_WAIVER'
      );
      const damageWaiverSelected = this.selectedAddonItems.find(
        (addon) => addon.type === 'DAMAGE_WAIVER'
      );
      return damageWaiverAvailable && !damageWaiverSelected;
    },
    totalPrice() {
      return this.totalRentalPrice + this.totalAddonsPrice;
    },
    cancellationDate() {
      const date = new Date(this.filters.pickupDate);
      date.setDate(date.getDate() - 1);
      return date;
    },
    minReturnDate() {
      if (!this.filters.pickupDate) return new Date();
      const minDate = new Date(this.filters.pickupDate);
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    },
    maxPickupDate() {
      // 90 days from now
      return new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
    },
    milesIncluded() {
      return 200 * this.rentalDays;
    },
    moreThan24HoursUntilPickup() {
      const now = new Date(); // Current time
      const timeDifference = this.filters.pickupDate - now;
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return hoursDifference > 24;
    },
    isUnlimitedMilesSelected() {
      const unlimitedMilesAddon = this.availableAddons.find(
        (addon) => addon.type === 'UNLIMITED_MILES'
      );
      return this.selectedAddons.includes(unlimitedMilesAddon?.id);
    },
    vehicleUnavailableDates() {
      // the keys where the value is false
      return Object.keys(this.vehicle.availability).filter(
        (key) => !this.vehicle.availability[key]
      );
    },
    pickupDateStr() {
      return formatDateToYYYYMMDD(this.filters.pickupDate);
    },
    returnDateStr() {
      return formatDateToYYYYMMDD(this.filters.returnDate);
    },
  },
  async created() {
    this.availableAddons = await api.addons.getAvailableAddons({
      vehicleVin: this.vehicle.vin,
      pickupAt: this.filters.pickupDate,
      returnAt: this.filters.returnDate
    });
  },
  methods: {
    ...mapActions('search', [
      'setPickupDate',
      'setReturnDate',
      'setPickupTime',
      'setReturnTime'
    ]),
    handleLearnMoreClick() {
      console.log("asdasd")
      window.open(`${process.env.VUE_APP_LANDING_ROOT_URL}/blog/how-does-mileage-work-when-renting-a-tesla-with-resla`, '_blank');
    },
    bookNow() {
      this.$emit('book', {
        pickupDate: this.pickupDateStr,
        returnDate: this.returnDateStr,
        pickupTime: this.filters.pickupTime,
        returnTime: this.filters.returnTime,
        selectedAddons: this.selectedAddonItems,
        redirectToDamageWaiver: this.redirectToDamageWaiver
      });
    },
    async handleBookingDates({ startDate, endDate }) {
      this.setPickupDate(startDate);
      this.setReturnDate(endDate);
      this.availableAddons = await api.addons.getAvailableAddons({
        vehicleVin: this.vehicle.vin,
        pickupAt: startDate,
        returnAt: endDate
      });
    },
    async handleBookingTime({ startTime, endTime }) {
      await this.setPickupTime(startTime);
      await this.setReturnTime(endTime);
    },
    handleBookingDateTime({ dateValue, timeValue }) {
      this.handleBookingDates(dateValue)
      this.handleBookingTime(timeValue)
    },
    updateSelectedAddons(addons) {
      this.selectedAddons = addons;
    },
    formatLabel(label) {
      return label
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    getAddonPeriodLabel(rentalDays) {
      switch (rentalDays) {
        case 1:
          return '1 day';
        case 7:
          return '1 week';
        case 14:
          return '2 weeks';
        case 21:
          return '3 weeks';
        case 28:
          return '1 month';
        default:
          return `${rentalDays} days`;
      }
    },
    getRackPeriodLabel(rentalDays) {
      switch (rentalDays) {
        case 1:
          return '1 day';
        case 7:
          return '1 week';
        case 14:
          return '2 weeks';
        case 21:
          return '3 weeks';
        case 28:
          return '1 month';
        default:
          return `${rentalDays} days`;
      }
    }
  }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
