export function formatDateToLocale(dateString, timezone) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: timezone || 'UTC'
    });
}

export function formatTimeToLocale(dateString, timezone) {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone || 'UTC'
    });
}

export function formatDateAndTimeToLocale(dateString, timezone) {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone || 'UTC'
    });
}

export function createLocalDate(dateString) {
    const dateParts = dateString.split("-"); // Split the input string (e.g., "2024-09-20")
    const year = parseInt(dateParts[0], 10); // Get the year
    const month = parseInt(dateParts[1], 10) - 1; // Get the month (months are 0-indexed in JS)
    const day = parseInt(dateParts[2], 10); // Get the day

    // Create a new Date object for midnight in the user's local time zone
    return new Date(year, month, day, 0, 0, 0);
}

export function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatTimeToHHMM(date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

export const getCookie = (name) => {
    // Construct the name to be searched for in the cookie string
    const nameEQ = name + "=";
    // Split the cookie string by semicolons (each cookie is separated by a semicolon)
    const ca = document.cookie.split(";");
  
    // Loop through the array of cookies
    for (let i = 0; i < ca.length; i++) {
      // Get each cookie and remove leading spaces
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      // Check if the current cookie starts with the name we're looking for
      if (c.indexOf(nameEQ) == 0) {
        // Return the value of the cookie (excluding the name and the equals sign)
        return c.substring(nameEQ.length, c.length);
      }
    }
    // Return null if the cookie is not found
    return null;
  };