<template>
  <div class="autocomplete">
    <TextInput id="address" class="!bg-transparent w-full focus-within:border-blue-500" is-border is-rounded
      label="Address" @input="fetchSuggestions" @focus="showDropdown = true"
      @blur="handleBlur" v-model="address" />
    <ul v-if="showDropdown && suggestions.length" class="autocomplete-dropdown">
      <li v-for="(suggestion, index) in suggestions" :key="index" @mousedown.prevent="selectSuggestion(suggestion)"
        class="autocomplete-item p-4">
        {{ `${suggestion.street_line} ${suggestion.secondary || ''}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`.trim() }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations,mapState } from 'vuex';
import TextInput from '../core/TextInput.vue';
export default {
  components: {
    TextInput,
  },
  data() {
    return {
      address: '',
      suggestions: [],
      showDropdown: false,
      selectedAddress: null,
    };
  },
  props: {
    defaultAddress: {
      type: String,
    },
  },
  mounted() {
    this.address = this.defaultAddress || '';
    if(this.address.length > 0){
      this.$emit('input', this.address)
    }
  },
  computed:{
    ...mapState('search', ['cities']),
    preferStates() {
    const states = [...new Set(this.cities?.map(location => location.state))];
    return states.join(';');
  },
  },
  methods: {
    ...mapMutations('search', ['cities']),
    async fetchSuggestions() {
      if (this.address.length < 1) {
        this.suggestions = [];
        return;
      }
      this.$emit('input', this.address)
      try {
        const response = await axios.get(
          `https://us-autocomplete-pro.api.smarty.com/lookup`,
          {
            params: {
              key: '222090818950913427',
              search: this.address,
              include_only_states:'ALLSTATES',
              prefer_states: this.preferStates,
            },
            headers: {
              'Referer': 'app.resla.com',
            }
          }
        )
        this.suggestions = response.data.suggestions.map(suggestion => ({
          secondary: suggestion.secondary,
          street_line: suggestion.street_line,
          city: suggestion.city,
          state: suggestion.state,
          zipcode: suggestion.zipcode,
        }));  
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    getSuggestionText(suggestion) {
      const part1 = [suggestion.street_line, suggestion.secondary, suggestion.city]
        .filter(Boolean)
        .join(' ')
      const part2 = [suggestion.state, suggestion.zipcode]
        .filter(Boolean)
        .join(' ')
      return [part1, part2].filter(Boolean).join(', ')
    },
    selectSuggestion(suggestion) {
      this.address = this.getSuggestionText(suggestion);
      this.selectedAddress = suggestion;
      this.suggestions = [];
      this.showDropdown = false;
      this.$emit('change', suggestion)
    },
    handleBlur(){
      this.$emit('change', this.address)
      this.$emit('input', this.address)
      setTimeout(() => {
        this.showDropdown = false;
      }, 50);
    }
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
  width: 100%;
  height: 40px !important;
}

.autocomplete-input {
  width: 100%;
  padding: 30px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px !important;

}

.autocomplete-dropdown {
  position: absolute;
  width: 100%;
  z-index: 999999;
  background-color: white !important;
  ;
  border: 1px solid #ccc;
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.autocomplete-item {
  padding: 10px;
  cursor: pointer;
  z-index: 999;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.selected-data {
  margin-top: 20px;
  font-size: 16px;
}
</style>