<template>
    <svg width="100%" height="100%" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.6191 6H11.3809C10.4239 6 9.54033 6.51276 9.06555 7.34363L5.2027 14.1036C4.74222 14.9095 4.5 15.8215 4.5 16.7497V24.6667C4.5 25.403 5.09695 26 5.83333 26H7.16667C7.90305 26 8.5 25.403 8.5 24.6667V23.3333H24.5V24.6667C24.5 25.403 25.097 26 25.8333 26H27.1667C27.903 26 28.5 25.403 28.5 24.6667V16.7497C28.5 15.8215 28.2578 14.9095 27.7973 14.1036L23.9345 7.34363C23.4597 6.51276 22.5761 6 21.6191 6Z"
            fill="#D6D2CD" />
        <path
            d="M9.83333 18.6667C9.83333 19.0349 9.53486 19.3333 9.16667 19.3333C8.79848 19.3333 8.5 19.0349 8.5 18.6667C8.5 18.2985 8.79848 18 9.16667 18C9.53486 18 9.83333 18.2985 9.83333 18.6667Z"
            fill="#D6D2CD" />
        <path
            d="M24.5 18.6667C24.5 19.0349 24.2015 19.3333 23.8333 19.3333C23.4651 19.3333 23.1667 19.0349 23.1667 18.6667C23.1667 18.2985 23.4651 18 23.8333 18C24.2015 18 24.5 18.2985 24.5 18.6667Z"
            fill="#D6D2CD" />
        <path
            d="M4.5 10.6667L8.12915 13.691C8.36877 13.8906 8.67082 14 8.98273 14H24.0173C24.3292 14 24.6312 13.8906 24.8708 13.691L28.5 10.6667M9.16667 18.6667H9.18M23.8333 18.6667H23.8467M11.3809 6H21.6191C22.5761 6 23.4597 6.51276 23.9345 7.34363L27.7973 14.1036C28.2578 14.9095 28.5 15.8215 28.5 16.7497V24.6667C28.5 25.403 27.903 26 27.1667 26H25.8333C25.097 26 24.5 25.403 24.5 24.6667V23.3333H8.5V24.6667C8.5 25.403 7.90305 26 7.16667 26H5.83333C5.09695 26 4.5 25.403 4.5 24.6667V16.7497C4.5 15.8215 4.74222 14.9095 5.2027 14.1036L9.06555 7.34363C9.54033 6.51276 10.4239 6 11.3809 6ZM9.83333 18.6667C9.83333 19.0349 9.53486 19.3333 9.16667 19.3333C8.79848 19.3333 8.5 19.0349 8.5 18.6667C8.5 18.2985 8.79848 18 9.16667 18C9.53486 18 9.83333 18.2985 9.83333 18.6667ZM24.5 18.6667C24.5 19.0349 24.2015 19.3333 23.8333 19.3333C23.4651 19.3333 23.1667 19.0349 23.1667 18.6667C23.1667 18.2985 23.4651 18 23.8333 18C24.2015 18 24.5 18.2985 24.5 18.6667Z"
            stroke="#54504C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>

<script>
export default {
    name: "CarIcon",
};
</script>