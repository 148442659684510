<template>
  <div class="max-w-7xl mx-auto pb-20">
    <div v-if="upcomingReservations.length > 0 || pastReservations.length > 0" class="bg-white p-6">
      <h1 class="text-6xl mb-10">My Trips</h1>
      <template v-if="upcomingReservations.length > 0">
        <h2 class="text-3xl mb-4 text-resla-ebony-20">Upcoming Reservations</h2>
        <div v-for="reservation in upcomingReservations" :key="reservation.id">
        <UpcomingReservationCard
          :orientation="'horizontal'"
          :reservation="reservation"
          @view-details="handleViewDetails"
          class="mb-4"
        />
      </div>
      </template>
      <template v-if="pastReservations.length > 0">
      <h2 class=" text-3xl mb-4 text-resla-ebony-20">Past Trips</h2>
      <PastReservationsList :pastReservations="pastReservations" />
      </template>
    </div>
    <div v-else class="py-32 flex flex-col items-center mx-auto text-center gap-6" :class="{'max-w-[23.3rem]':!loading}">
      <div v-if="loading" class="grid grid-cols-1 md:grid-cols-1 gap-10 justify-center">
        <div class="flex gap-2">   
          <div class="flex gap-2 justify-between">
            <SkeletonLoader width="300px" height="264px" rounded border-radius="4px" />
            <SkeletonLoader width="450px" height="264px" rounded border-radius="4px" />
          </div>
        </div>
        <div class="flex gap-2">
          <div class="flex gap-2 justify-between">
            <SkeletonLoader width="300px" height="264px" rounded border-radius="4px" />
            <SkeletonLoader width="450px" height="264px" rounded border-radius="4px" />
          </div>
        </div>
      </div>
         <div  v-if="!loading" class="size-16 bg-primary-800 rounded-full flex items-center justify-center">
             <CarIcon class="size-8"/>
         </div>
         <div  v-if="!loading" class="space-y-3 text-primary-100">
            <h2 class="font-medium text-[56px] leading-[56px] tracking-negative-3">No trips yet!</h2>
            <p class="font-urbanist font-normal text-xl leading-8">Schedule your first trip by booking one of our vehicles. Let’s get adventuring!</p>
         </div>
         <CtaButton
           v-if="!loading"
            :to="'/search'"
            :variant="'primary'"
            :trailing-icon="true"
            :size="'large'"
            >Models</CtaButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UpcomingReservationCard from '@/components/cards/UpcomingReservationCard.vue';
import PastReservationsList from '@/components/my-trips/PastReservationsList.vue';
import CarIcon from '@/components/core/icons/CarIcon.vue';
import CtaButton from '@/components/core/CtaButton.vue';
import SkeletonLoader from '@/components/core/animations/SkeletonLoader.vue';


export default {
  name: "TripsView",
  data(){
    return {
      loading:false
    }
  },
  components: {
    UpcomingReservationCard,
    PastReservationsList,
    CarIcon,
    CtaButton,
    SkeletonLoader
  },
  computed: {
    ...mapGetters('reservations', ['upcomingReservations', 'pastReservations']),
  },
  methods: {
    ...mapActions('reservations', ['getReservations']),
    handleViewDetails(reservation) {
      this.$router.push({
        name: 'trip',
        params: { platform_id: reservation.id },
      });
    },
  },
  async created() {
    this.loading = true
     await this.getReservations();
     this.loading = false
  },
};
</script>
