<template>
    <Modal id="disqualified-renter-modal" title="We're Unable To Approve Your Rental"
        class="!items-end md:!items-center" :value="value" :modal-class="'max-w-[560px]'"
        :content-class="'max-w-[560px]'" :show-header="false" :show-footer="false">
        <div class="flex flex-col gap-y-5">
            <div class="flex items-center justify-between">
                <div class="size-8 rounded-lg bg-[#F4E2EA] flex items-center justify-center"><svg width="20" height="20"
                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 4.16666V12.5M10 15.8333H10.0083" stroke="#DD4C53" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <button type="button"
                    class="bg-transparent hover:bg-gray-200 text-gray-400 hover:text-gray-900 rounded-md text-sm size-6 ms-auto inline-flex justify-center items-center"
                    @click="closeModal(false)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4.46967 4.46967C4.76256 4.17678 5.23744 4.17678 5.53033 4.46967L10 8.93934L14.4697 4.46967C14.7626 4.17678 15.2374 4.17678 15.5303 4.46967C15.8232 4.76256 15.8232 5.23744 15.5303 5.53033L11.0607 10L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7626 15.8232 14.4697 15.5303L10 11.0607L5.53033 15.5303C5.23744 15.8232 4.76256 15.8232 4.46967 15.5303C4.17678 15.2374 4.17678 14.7626 4.46967 14.4697L8.93934 10L4.46967 5.53033C4.17678 5.23744 4.17678 4.76256 4.46967 4.46967Z"
                            fill="#2B2825" />
                    </svg>


                </button>
            </div>
            <div>
                <p class="font-medium text-[32px] leading-8 tracking-negative-3 text-primary-100">Sorry, you don't
                    currently
                    qualify to rent with us.</p>
                <p class="mt-3 font-urbanist font-normal text-xl leading-8">Don't worry, your card has not been
                    charged, and your
                    reservation has been automatically cancelled. We hope you consider us again in the future.</p>
            </div>

            <div class="flex items-center gap-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="9" fill="#E5E3E0" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM10.75 10C10.75 9.30964 11.3096 8.75 12 8.75C12.6904 8.75 13.25 9.30964 13.25 10V10.1213C13.25 10.485 13.1055 10.8338 12.8483 11.091L11.4697 12.4697C11.1768 12.7626 11.1768 13.2374 11.4697 13.5303C11.7626 13.8232 12.2374 13.8232 12.5303 13.5303L13.909 12.1517C14.4475 11.6132 14.75 10.8828 14.75 10.1213V10C14.75 8.48122 13.5188 7.25 12 7.25C10.4812 7.25 9.25 8.48122 9.25 10V10.5C9.25 10.9142 9.58579 11.25 10 11.25C10.4142 11.25 10.75 10.9142 10.75 10.5V10Z"
                        fill="#44403C" />
                </svg>
                <p class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">Questions?</p>
                <p class="font-urbanist font-normal text-base leading-6.5 text-primary-100">Contact support at (123) 456
                    7890
                </p>



            </div>
            <CtaButton variant="primary" size="large" class="w-full whitespace-nowrap" @click="closeModal(true)">Back to
                Home</CtaButton>
        </div>
    </Modal>
</template>


<script>
import Modal from '../core/Modal.vue';
import CtaButton from '../core/CtaButton.vue';


export default {
    components: {
        Modal,
        CtaButton
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(newValue) {
            if (newValue) {
                this.$store.commit("activateOverlay");
            }
        }
    },
    methods: {
        closeModal(goBack = false) {
            this.$emit("input");
            if (goBack)
                this.$router.push('/')
            this.$store.commit("deactivateOverlay");
        },
    },
    mounted() {
        if (this.value) {
            this.$store.commit("activateOverlay");
        }
    }
};
</script>


<style scoped>
/* Custom styling for the modal, if necessary */
</style>