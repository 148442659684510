var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-primary-900 flex flex-col gap-5 p-6 rounded-xl border border-primary-800"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('p',{staticClass:"text-heading tracking-negative-3 font-medium text-primary-100"},[_vm._v(" $"+_vm._s(_vm.vehicle.rack_rate / 100)),_c('span',{staticClass:"text-base leading-6.5"},[_vm._v("/day")])]),_c('div',{staticClass:"flex items-center"},[_c('GreenTickIcon',{staticClass:"size-4"}),_c('p',{staticClass:"ml-2 mr-3 text-primary-200 text-sm font-urbanist"},[(_vm.isUnlimitedMilesSelected)?_c('span',[_vm._v("unlimited miles")]):_c('span',[_vm._v(_vm._s(_vm.milesIncluded)+" miles included")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: ("Here’s how we calculate miles per day. <a class='underline' href='" + _vm.landingBaseURL + "/blog/how-does-mileage-work-when-renting-a-tesla-with-resla' target='_blank'>Learn more</a>"),
          maxWidth: 200,
          placement: 'top',
          interactive: true
        }),expression:"{\n          content: `Here’s how we calculate miles per day. <a class='underline' href='${landingBaseURL}/blog/how-does-mileage-work-when-renting-a-tesla-with-resla' target='_blank'>Learn more</a>`,\n          maxWidth: 200,\n          placement: 'top',\n          interactive: true\n        }"}],staticClass:"cursor-pointer"},[_c('InfoIcon',{staticClass:"size-5"})],1)],1)]),_c('div',{staticClass:"border border-primary-800 rounded-lg"},[_c('DatepickerModal',{staticClass:"w-full",attrs:{"id":"booking-card-date-selection","label":"Booking Dates","rounded":"","date-value":{
        startDate: _vm.filters.pickupDate,
        endDate: _vm.filters.returnDate,
      },"time-value":{
          startTime: _vm.filters.pickupTime,
          endTime: _vm.filters.returnTime,
        },"disabled-dates":_vm.vehicleUnavailableDates,"max-date":_vm.maxPickupDate},on:{"apply":_vm.handleBookingDateTime}})],1),_c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',{staticClass:"flex justify-between font-urbanist text-primary-100 text-base leading-6.5 font-normal"},[_c('span',[_vm._v("$"+_vm._s(_vm.vehicle.rack_rate / 100)+" x "+_vm._s(_vm.getRackPeriodLabel(_vm.rentalDays))+" ")]),_c('span',[_vm._v("$"+_vm._s(_vm.totalRentalPrice / 100))])]),_vm._l((_vm.selectedAddonItems),function(addon){return _c('div',{key:addon.id,staticClass:"flex justify-between font-urbanist text-primary-100 text-base leading-6.5 font-normal"},[_c('span',[_vm._v(_vm._s(_vm.formatLabel(addon.description))+" x "+_vm._s(_vm.getAddonPeriodLabel(_vm.rentalDays))+" ")]),_c('span',[_vm._v("$"+_vm._s((addon.amount * _vm.rentalDays) / 100))])])}),_c('div',{staticClass:"w-full h-px bg-primary-700"}),_c('div',{staticClass:"flex justify-between font-urbanist text-primary-100 text-base leading-6.5 font-medium"},[_c('span',[_vm._v("Subtotal")]),_c('span',[_vm._v("$"+_vm._s(_vm.totalPrice / 100))])])],2),(_vm.availableAddons.length > 0)?_c('AvailableAddons',{attrs:{"availableAddons":_vm.availableAddons},on:{"update:addons":_vm.updateSelectedAddons}}):[_c('div',{staticClass:"flex justify-between items-center mb-4 rounded-lg"},[_c('SkeletonLoader',{attrs:{"width":"100%","height":"57px","rounded":"","border-radius":"4px","animation":"wave"}})],1),_c('div',{staticClass:"flex justify-between items-center mb-4 rounded-lg"},[_c('SkeletonLoader',{attrs:{"width":"100%","height":"57px","rounded":"","border-radius":"4px","animation":"wave"}})],1)],_c('CtaButton',{staticClass:"w-full",attrs:{"is-disabled":_vm.isBookingLoading,"is-loading":_vm.isBookingLoading},on:{"click":_vm.bookNow}},[_vm._v(" Book Now ")])],2),(_vm.moreThan24HoursUntilPickup)?_c('FreeCancellationCard',{staticClass:"mt-5",attrs:{"cancellationDate":_vm.cancellationDate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }