<template>
  <div
    class="flex flex-col w-full mx-auto max-w-[42.5rem] md:px-0 px-6 py-24 gap-y-8 md:gap-y-10"
  >
    <!--header-->
    <div
      class="flex flex-col md:flex-row gap-5 md:items-center justify-between"
    >
      <div>
        <CtaButton
          variant="tertiary"
          leading-icon
          :to="'/trip/' + $route.params.platform_id"
          class="w-max"
        >
          My Trip
        </CtaButton>
        <h2
          class="text-heading tracking-negative-3 text-primary-100 font-medium mt-3"
        >
          Your Resla Receipt
        </h2>
        <p class="font-urbanist text-base leading-6.5 text-primary-100 mt-2">
          Receipt ID {{ reservation.id }} •
          {{ formatDateToLocale(reservation.pickup_at) }}
        </p>
      </div>
      <div class="flex items-center gap-5">
        <CtaButton variant="tertiary" @click="handlePoliciesClick">
          Policies <ArrowUpRight slot="trailing" class="size-4"
        /></CtaButton>
        <CtaButton variant="tertiary" @click="handlePrint">
          Print <PrinterIcon slot="trailing" class="size-5"
        /></CtaButton>
      </div>
    </div>
    <!-- Vehicle Info -->
    <div
      class="flex flex-col md:hidden bg-primary-900 border border-primary-700 p-6 gap-2 rounded-lg"
    >
      <p class="font-urbanist text-base leading-6.5 text-primary-100">Total</p>
      <p
        class="text-heading tracking-negative-3 text-primary-100 font-medium mt-2"
      >
        ${{ totalPrice / 100 }}
      </p>
    </div>
    <div class="flex items-center gap-3">
      <img
        :src="getMoneyshotImage(reservation.vehicle.images)"
        class="w-28 h-20 object-cover rounded-xl"
      />
      <div class="flex-1">
        <h3
          class="text-2xl leading-9 tracking-negative-2 font-medium text-primary-100"
        >
          {{ reservation.vehicle.model.name }}
        </h3>
        <p class="font-urbanist text-base leading-6.5 text-primary-100">
          {{ reservation.vehicle.vin }}
        </p>
        <p class="font-urbanist text-base leading-6.5 text-primary-100">
          {{ formatDateToLocale(reservation.pickup_at) }} -
          {{ formatDateToLocale(reservation.return_at) }}
        </p>
      </div>
      <div class="hidden md:block w-px min-h-20 bg-primary-700"></div>
      <div class="hidden md:block pr-2">
        <p class="font-urbanist text-base leading-6.5 text-primary-100">
          Total
        </p>
        <p
          class="text-3xl leading-8 tracking-negative-3 text-primary-100 font-medium mt-2"
        >
          ${{ totalPrice / 100 }}
        </p>
      </div>
    </div>
    <!-- Reservation Line Items -->
    <ReservationLineItems
      class="border-t border-primary-700"
      :reservation="reservation"
      :statuses="['Successful']"
    />
    <!-- Payment Info -->
    <!-- <div class="flex px-3 justify-between">
      <p
        class="font-urbanist font-semibold text-base leading-6.5 text-primary-100"
      >
        Payment
      </p>
      <p
        class="font-urbanist text-base leading-6.5 text-primary-100 flex items-center"
      >
        <CreditCardIcon class="size-5 mr-2" />{{
          reservation.payment_method
        }}
        ending in {{ reservation.card_last_four }}
      </p>
    </div> -->
    <ReceiptCard v-for="(transaction, key, index) in sortedReceipt" :key="key" :open="index === 0" :receipt-id="`Receipt ${reservation.id.split('-')[1]}-${String(
      index + 1
    ).padStart(3, '0')}`" :transactions="transaction" :time-zone="reservation.pickup_location.timezone" :date="key"
      :payment-method="reservation.payment_method" :card-digit="reservation.card_last_four">
    </ReceiptCard>
  </div>
</template>

<script>
import { formatDateToLocale, formatTimeToLocale } from '@/helpers/util';
import ReservationLineItems from '@/components/cards/ReservationLineItems.vue';
import CtaButton from '@/components/core/CtaButton.vue';
import PrinterIcon from '@/components/core/icons/PrinterIcon.vue';
import ArrowUpRight from '@/components/core/icons/ArrowUpRight.vue';
// import CreditCardIcon from '@/components/core/icons/CreditCardIcon.vue';
import ReceiptCard from '@/components/cards/ReceiptCard.vue';
import api from '@/api';

export default {
  name: 'TokenReceiptView',
  components: {
    ReservationLineItems,
    CtaButton,
    ArrowUpRight,
    PrinterIcon,
    // CreditCardIcon,
    ReceiptCard
  },
  data() {
    return {
      reservation: null
    };
  },
  computed: {
    totalPrice() {
      return this.reservation.transactions.reduce(
        (acc, item) => acc + item.captured_amount,
        0
      );
    },
    transactionGrouped() {
      return !this.reservation ? {} : this.reservation.transactions.reduce((acc, item) => {
        if (item.category === 'Tax' && item.captured_amount === 0) {
          return acc;
        }

        const key = item.initiated_at;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        acc[key].sort((a, b) => {
          if (a.category === 'Rack') return -1;
          if (b.category === 'Rack') return 1;
          if (a.category === 'Addon') return -1;
          if (b.category === 'Addon') return 1;
          if (a.category === 'Additional') return -1;
          if (b.category === 'Additional') return 1;
          if (a.category === 'Deposit') return -1;
          if (b.category === 'Deposit') return 1;
          return 0;
        });
        return acc;
      }, {});
    },
    sortedReceipt() {
      const groupedTransactions = this.transactionGrouped;
      return Object.keys(groupedTransactions)
        .sort((a, b) => new Date(a) - new Date(b))
        .reduce((acc, key) => {
          acc[key] = groupedTransactions[key];
          return acc;
        }, {});
    }
  },
  methods: {
    handlePrint() {
      window.print();
    },
    handlePoliciesClick() {
      window.open(`${process.env.VUE_APP_LANDING_ROOT_URL}/policies`, '_blank');
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')
          ?.image_url;
    },
    formatDateToLocale,
    formatTimeToLocale
  },
  async created() {
    const response = await api.reservation.getReservationWithToken(
    this.$route.query.token
    );
    this.reservation = response;
  }
};
</script>

<style scoped></style>
