import axios from 'axios';
import reservationService from './reservation';
import paymentService from './payments';
import userService from './user';
import vehicleService from './vehicles';
import vehicleModelsService from './vehicleModels';
import { getCookie } from '@/helpers/util';
import locationService from './location';
import addonService from './addons';
import agreementService from './agreements';
import verificationService from './verifications';
import discountService from './disounts';

class API {
    constructor(rootUrl) {
        this.rootUrl = rootUrl;
    }

    async request({
        method,
        url,
        data = null,
        params = null
    }) {
        try {
            // console.error({ method, url, data, params });
            const response = await axios({
                method,
                url: `${this.rootUrl}${url}`,
                data,
                params,
                headers: {
                    stytch_auth: getCookie("stytch_session"),
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Error making ${method} request to ${url}:`, error);
            throw error;
        }
    }
}

const apiInstance = new API(process.env.VUE_APP_ROOT_URL);

const api = {
    addons: addonService(apiInstance),
    reservation: reservationService(apiInstance),
    payment: paymentService(apiInstance),
    user: userService(apiInstance),
    vehicle: vehicleService(apiInstance),
    vehicleModels: vehicleModelsService(apiInstance),
    location: locationService(apiInstance),
    agreements: agreementService(apiInstance),
    verifcations: verificationService(apiInstance),
    discounts: discountService(apiInstance),
};

export default api;
