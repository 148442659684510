const reservationService = (api) => ({
  createReservation: async (reservation) => {
    return api.request({
      method: 'post',
      url: '/api/v1/reservations',
      data: reservation
    });
  },
  getReservations: async () => {
    return api.request({
      method: 'get',
      url: '/api/v1/reservations'
    });
  },
  applyAddon: async (platformId, addonIds) => {
    return api.request({
      method: 'patch',
      url: '/api/v1/reservations/addons',
      data: {
        platform_id: platformId,
        addon_ids: addonIds
      }
    });
  },
  getReservationWithToken: async (token) => {
    console.log(token);
    return api.request({
      method: 'get',
      url: `/api/admin/v1/transactions/receipts?token=${token}`
    });
  },
  getEligibilty: async (token) => {
    console.log("dd",token);
    return api.request({
      method: 'post',
      url: `/api/v1/reservations/check_eligibility`,
      data:token.platformId
    });
  },
  checkConflicts: async ({
    vehicleVin,
    platformId
  }) => {
    return api.request({
      method: 'post',
      url: '/api/v1/reservations/check-conflicts',
      data: {
        vehicle_vin: vehicleVin,
        platform_id: platformId
      }
    });
  }
});

export default reservationService;
