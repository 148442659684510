<template>
  <div class="w-full  ">
    <div
      class="flex w-full items-center justify-between py-4 px-5 cursor-pointer bg-primary-900 border rounded-lg border-primary-700"
      @click="toggleAccordion">
      <p class="font-urbanist font-semibold text-xl leading-8 text-primary-100">
        {{ receiptId }}
      </p>
      <div class="flex items-center gap-2 md:gap-4">
        <div class="flex gap-1 items-start md:items-center">
          <CalendarIcon class="size-5 mr-1 mt-1 md:mt-0"></CalendarIcon>
          <p class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
            formatDateToLocaleWithTimezone(date) }}</p>
        </div>
        <ChevronLeft class="rotate-[270deg] size-6 transform transition-transform duration-200 text-primary-300"
          :class="{ '!rotate-90': isOpen }"></ChevronLeft>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isOpen">
        <div class="flex flex-col gap-3 py-4 px-5 overflow-hidden">
          <!--Rack Item-->
          <div v-for="rack in groupedRackItem" :key="rack.id" class="flex justify-between">
            <div class="flex items-center gap-3">
              <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
                rack.description.charAt(0).toUpperCase() +
                rack.description.slice(1)
                }}</span>
            </div>
            <div class="flex items-center gap-16">
              <p class="font-urbanist font-normal text-sm text-primary-100">
                ${{
                  formatPrice(rack.amount / 100)
                }}
                x {{ rack.count }} day{{ rack.count > 1 ? 's' : '' }}
              </p>
              <p class="font-urbanist text-primary-100">${{
                formatPrice(rack.totalAmount / 100) }}</p>
            </div>
          </div>
          <!--Addon Item-->
          <div v-for="(addon, key) in groupedAddons" :key="key" class="flex justify-between">
            <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
              addon.description.charAt(0).toUpperCase() +
              addon.description.slice(1)
              }}</span>
            <div class="flex items-center gap-16">
              <p v-if="addon.category === 'Addon' && addon.number_of_days"
                class="font-urbanist font-normal text-sm text-primary-100">
                ${{
                  formatPrice(addon.cost / addon.number_of_days / 100)
                }}
                x {{ addon.number_of_days }} day{{ addon.number_of_days > 1 ? 's' : '' }}
              </p>
              <span class="font-urbanist text-primary-100">${{
                formatPrice(addon.cost / 100) }}</span>
            </div>
          </div>
          <div v-for="transaction in filterTransactions" :key="transaction.id" class="">
            <div class="flex justify-between">
              <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
                transaction.description.charAt(0).toUpperCase() + transaction.description.slice(1) }}</span>
              <div class="flex items-center gap-6 md:gap-16">
                <p v-if="transaction.category === 'Addon'" class="font-urbanist font-normal text-sm text-primary-100">
                  ${{ formatPrice(transaction.addon.amount / 100) }} x {{ transaction.number_of_days }} days
                </p>
                <p v-if="transaction.category === 'Rack'" class="font-urbanist font-normal text-sm text-primary-100">
                  ${{ formatPrice(transaction.cost / transaction.number_of_days / 100) }} x {{
                    transaction.number_of_days }} days
                </p>
                <span class="font-urbanist text-primary-100">${{
                  formatPrice(transaction.cost / 100) }}</span>
              </div>

            </div>
          </div>
          <!--total discount-->
          <div v-if="totalDiscount > 0" class="flex justify-between">
            <Badge size="large" class="flex gap-1 items-center">Total Discount <TagIcon class="size-4"></TagIcon>
            </Badge>
            <span class="font-urbanist font-semibold text-base leading-6.5 text-verde-40">
              - ${{ formatPrice(totalDiscount / 100) }}
            </span>
          </div>
        </div>
        <div class="flex w-full justify-between py-4 px-5 mt-2 border-t border-primary-700">
          <p class="font-urbanist font-normal text-base leading-6.5 text-primary-100">Total</p>
          <div class="flex items-center gap-2 md:gap-6">

            <p class="font-urbanist text-base leading-6.5 text-primary-100 flex items-start md:items-center">
              <span class="font-semibold hidden md:block mr-3 text-primary-400">Payment</span>
              <CreditCardIcon class="size-5 mr-2" />{{
                paymentMethod
              }}
              ending in {{ cardDigit }}
            </p>
            <p class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">${{
              formatPrice(totalPrice / 100) }}</p>
          </div>
        </div>
      </div>

    </transition>
  </div>
</template>

<script>
import { formatDateAndTimeToLocale } from '@/helpers/util';
import Badge from '@/components/core/Badge'
import CalendarIcon from '@/components/core/icons/CalendarIcon'
import ChevronLeft from '@/components/core/icons/ChevronLeft.vue';
import CreditCardIcon from '@/components/core/icons/CreditCardIcon.vue';
import TagIcon from '@/components/core/icons/TagIcon.vue';
export default {
  name: 'ReceiptCard',
  components: {
    Badge,
    CalendarIcon,
    ChevronLeft,
    CreditCardIcon,
    TagIcon
  },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    receiptId: {
      type: String
    },
    date: {
      type: String
    },
    paymentMethod: {
      type: String,
      default: 'Visa'
    },
    cardDigit: {
      type: String,
      default: '2352'
    },
    open: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: this.open,
      rackTransactions: this.transactions.filter(transaction => transaction.category === 'Rack'),
      addonTransactions: this.transactions.filter(transaction => transaction.category === 'Addon'),
    };
  },
  computed: {
    groupedRackItem() {
      const rackGrouped = this.rackTransactions.reduce((acc, item) => {
        const key = item.cost
        if (!acc[key]) {
          acc[key] = {
            startDate: item.effective_date,
            endDate: item.effective_date,
            amount: item.cost,
            count: 0,
            totalAmount: 0,
            description: item.description,
            other: { ...item },
          };
        }
        acc[key].count++;
        acc[key].totalAmount += item.cost;
        acc[key].startDate = acc[key].startDate < item.effective_date ? acc[key].startDate : item.effective_date;
        acc[key].endDate = acc[key].endDate > item.effective_date ? acc[key].endDate : item.effective_date;
        return acc;
      }, {});
      return Object.values(rackGrouped);
    },
    groupedAddons() {
      const addonsGrouped = this.addonTransactions.reduce((acc, item) => {
        const key = `${item.description.trim().toUpperCase()}-${item.cost}`;


        if (!acc[key]) {
          acc[key] = { ...item };
        } else {
          acc[key] = {
            ...item,
            cost: acc[key].cost + item.cost,
            number_of_days: acc[key].number_of_days + item.number_of_days
          };
        }
        return acc;
      }, {});
      return addonsGrouped;
    },
    filterTransactions() {
      return this.transactions.filter(
        transaction => transaction.category !== 'Rack' && transaction.category !== 'Addon'
      );
    },
    totalDiscount() {
      return this.transactions.reduce((sum, transaction) => {
        return sum + (transaction.discount_amount || 0);
      }, 0);
    },
    totalPrice() {
      const totalCaptured = this.transactions.reduce(
        (acc, transaction) => acc + transaction.captured_amount,
        0
      );
      return totalCaptured;
    },
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },

    formatPrice(price) {
      return price % 1 === 0 ? price : price.toFixed(2);
    },
    formatDateToLocaleWithTimezone(date) {
      return formatDateAndTimeToLocale(date, this.timezone);
    }
  }
};
</script>
