<template>
  <div class="flex flex-col" id="search-container">
    <template v-if="loading">
      <div class="flex items-center p-4 border-b bg-white overflow-x-hidden space-x-3">
        <SkeletonLoader v-for="n in 7" :key="n" :width="80" :height="40" rounded radius="100px"></SkeletonLoader>
        <div class="hidden md:flex space-x-3">
          <SkeletonLoader v-for="n in 4" :key="n" size="40px" rounded type="circle"></SkeletonLoader>
        </div>
      </div>
      <div class="grid md:grid-cols-2 flex-1 gap-1">
        <div class="grid items-center grid-cols-2 gap-3 p-4 w-full">
          <SkeletonLoader v-for="n in 4" :key="n" width="100%" height="100%" rounded></SkeletonLoader>
        </div>
        <div class="grid">
          <SkeletonLoader width="100%" height="100%"></SkeletonLoader>
        </div>
      </div>
    </template>
    <template v-if="!loading">
      <FilterBar @filter-change="handleFilterChange" :selectedFilters="filters" :numSearchResults="filteredCars.length"
        :searchResultsIsLoading="loading" />
      <!--mobile nav-->
      <div class="md:hidden flex justify-between p-6 items-center text-primary-300 font-medium">
        <p class="text-2xl leading-9 tracking-negative-2">Vehicles</p>
        <div class="flex items-center gap-4">
          <p class="text-sm">{{ filteredCars.length }} Results</p>
          <Toggle :options="toggleOptions" v-model="toggleView"> </Toggle>
        </div>
      </div>
      <div class="flex flex-1 overflow-hidden">
        <!-- List View (Left Half) -->
        <div v-if="toggleView === 'list' || isExpanded"
          class="w-full overflow-y-scroll p-4 transition-all duration-300 ease-in-out">
          <div v-if="filteredCars.length === 0"
            class="flex flex-col h-full w-full items-center justify-center gap-6 p-6">
            <NoCarsIcon class="size-20"></NoCarsIcon>
            <div class="w-60">
              <p class="text-2xl leading-9 tracking-negative-2 text-center font-medium text-primary-100">
                There are no cars that match your search.
              </p>
              <p class="font-urbanist text-primary-300 font-normal text-center text-base leading-6.5">
                Try modifying your search to view vehicles in your area.
              </p>
            </div>
            <CtaButton variant="secondary" @click="clearFilters">Clear Filters</CtaButton>
          </div>
          <div v-else class="grid md:grid-cols-2 gap-6">
            <template v-if="isFiltering">
              <SkeletonLoader v-for="n in 6" :key="n" width="100%" height="246px" rounded></SkeletonLoader>
            </template>
            <template v-else>
              <VehicleCard v-for="vehicle in filteredCars" :key="vehicle.id" :image="getMoneyshotImage(vehicle.images)"
                :model="vehicle.model.name" :rackRate="vehicle.rack_rate"
                :paymentBalance="getPaymentBalanceForVehicle(vehicle)" :vin="vehicle.vin" />
            </template>
          </div>
        </div>


        <!-- Map View (Right Half) -->
        <div v-if="toggleView === 'map' || isExpanded"
          class="w-full relative overflow-hidden transition-all duration-300 ease-in-out">
          <VehicleMap v-if="filters.city" :latitude="filters.city.latitude" :longitude="filters.city.longitude"
            :markers="mapMarkers" @map-moved="updateMapBounds" class="w-full h-full" />
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { createLocalDate } from "@/helpers/util";

import FilterBar from "@/components/search/FilterBar.vue";
import VehicleCard from "@/components/search/VehicleCard.vue";
import VehicleMap from "@/components/maps/VehicleMap.vue";
import SkeletonLoader from "@/components/core/animations/SkeletonLoader.vue";
import NoCarsIcon from "@/components/core/icons/NoCarsIcon.vue";
import CtaButton from "@/components/core/CtaButton.vue";
import Toggle from "@/components/core/ToggleButton.vue";
import MapViewIcon from "@/components/core/icons/MapViewIcon.vue";
import ListViewIcon from "@/components/core/icons/ListViewIcon.vue";


export default {
  name: "SearchView",
  components: {
    FilterBar,
    VehicleCard,
    VehicleMap,
    SkeletonLoader,
    CtaButton,
    NoCarsIcon,
    Toggle,
  },
  data() {
    return {
      isExpanded: false,
      isInitialized: false,
      toggleView: "list",
      toggleOptions: [
        { value: "map", icon: MapViewIcon },
        { value: "list", icon: ListViewIcon },
      ],
      mapBounds: null,
      filteredCars: [],
      isFiltering: false,
    };
  },
  computed: {
    ...mapState("search", ["filters", "vehicles", "loading", "cities"]),
    ...mapState("app", ["userLocationAllowed"]),
    ...mapGetters("search", ["numRentedDays"]),
    mapMarkers() {
      return this.vehicles.map((vehicle) => ({
        latitude: vehicle.location?.latitude,
        longitude: vehicle.location?.longitude,
        title: vehicle.model.name,
        description: `<div class='rounded-lg flex flex-col gap-3 bg-white max-w-40 pt-3.5'>
          <img src="${this.getMoneyshotImage(vehicle.images)}" alt="${vehicle.model.name
          }" class="w-full h-auto rounded-xl" />
          <div class='flex justify-between items-center'>
          <div>
            <p class="text-primary-100 text-base font-medium leading-6.5">${vehicle.model.name
          }</p>
            <p class="text-sm font-medium text-primary-300">
            $${this.getPaymentBalanceForVehicle(vehicle) / 100}
            <span class='ml-2 px-2 py-0.5 rounded !text-xs font-normal bg-primary-800'>$${vehicle.rack_rate / 100
          }/day</span>
            </p>
          </div>
          <a href='/vehicle/${vehicle.vin}' 
              ><svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.9697 5.46967C13.2626 5.17678 13.7374 5.17678 14.0303 5.46967L20.0303 11.4697C20.3232 11.7626 20.3232 12.2374 20.0303 12.5303L14.0303 18.5303C13.7374 18.8232 13.2626 18.8232 12.9697 18.5303C12.6768 18.2374 12.6768 17.7626 12.9697 17.4697L17.6893 12.75H5.5C5.08579 12.75 4.75 12.4142 4.75 12C4.75 11.5858 5.08579 11.25 5.5 11.25H17.6893L12.9697 6.53033C12.6768 6.23744 12.6768 5.76256 12.9697 5.46967Z" />
          </svg></a>
          </div>
            <p class='text-xs font-normal text-primary-100'>${vehicle.location.name
          }</p> 
           
          </div>`,
      }));
    },
  },
  watch: {
    // Watch filters to fetch vehicles when user is on the search page and page mounted
    'filters.city': {
      handler(newCity, oldCity) {
        if (this.isInitialized && (newCity?.name !== oldCity?.name)) {
          this.updateQueryParam({ city: `${newCity.name}, ${newCity.state}` })
          this.searchVehicles()
        }
      }
    },
    'filters.pickupDate': {
      handler(newPickupDate, oldPickupDate) {
        if (this.isInitialized && (new Date(newPickupDate).getTime() !== new Date(oldPickupDate).getTime())) {
          this.updateQueryParam({ pickupDate: this.formatDateToString(newPickupDate) })
          this.searchVehicles()
        }
      }
    },
    'filters.returnDate': {
      handler(newReturnDate, oldReturnDate) {
        if (this.isInitialized && (new Date(newReturnDate).getTime() !== new Date(oldReturnDate).getTime())) {
          this.updateQueryParam({ returnDate: this.formatDateToString(newReturnDate) })
          this.searchVehicles();
        }
      }
    },
    'filters.model': {
      handler(newModel, oldModel) {
        if (this.isInitialized && (newModel !== oldModel)) {
          this.updateQueryParam({ model: newModel })
          this.searchVehicles();
        }
      }
    },
    'filters.color': {
      handler(newColor, oldColor) {
        if (this.isInitialized && (newColor !== oldColor)) {
          this.updateQueryParam({ color: newColor })
          this.searchVehicles();
        }
      }
    },
    'mapBounds': {
      immediate: true,
      handler(newBounds) {
        this.filteredVehicles(newBounds);
      },
    },
    'vehicles': {
      handler(newVehicles) {
        this.filteredCars = newVehicles;
      },
    },
  },
  methods: {
    ...mapActions("search", [
      "toggleFilter",
      "searchVehicles",
      "setCity",
      "setPickupDate",
      "setReturnDate",
      "getCities",
      "setDefaultCityFilter",
      "setDefaultPickupDate",
      "setDefaultReturnDate",
      "setDefaultPickupTime",
      "setDefaultReturnTime",
      "setDefaultDateFilters",
    ]),
    async filteredVehicles(bounds) {
      this.isFiltering = true;
      if (!bounds) {
        this.filteredCars = this.vehicles;
      } else {
        const { _sw, _ne } = bounds;
        this.filteredCars = this.vehicles.filter(car => {
          const { latitude, longitude } = car.location;
          return (
            latitude >= _sw.lat &&
            latitude <= _ne.lat &&
            longitude >= _sw.lng &&
            longitude <= _ne.lng
          );
        });
      }
      // simulate delay for ux
      await new Promise(resolve => setTimeout(resolve, 300));
      this.isFiltering = false;
    },
    updateMapBounds(newBounds) {
      this.mapBounds = newBounds;
    },
    getMoneyshotImage(vehicleImages) {
      return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    },
    checkViewport() {
      this.isExpanded = window.innerWidth >= 768; //  768px as the desktop breakpoint tailwind md
    },
    handleFilterChange({ filter, value }) {
      this.toggleFilter({ filter, value });
    },
    clearFilters() {
      this.toggleFilter({ filter: "model", value: this.filters.model });
      this.toggleFilter({ filter: "color", value: this.filters.color });
      this.setDefaultCityFilter();
      this.setDefaultDateFilters();
    },
    getPaymentBalanceForVehicle(vehicle) {
      return vehicle.rack_rate * this.numRentedDays;
    },
    setQueryParams() {
      const isoPickupDate = this.filters.pickupDate
        ? this.formatDateToString(this.filters.pickupDate)
        : '';
      const isoReturnDate = this.filters.returnDate
        ? this.formatDateToString(this.filters.returnDate)
        : '';
      const query = {
        city: this.filters.city
          ? `${this.filters.city.name}, ${this.filters.city.state}`
          : '',
        pickupDate: isoPickupDate,
        returnDate: isoReturnDate,
      };


      if (this.filters.model) {
        query.model = this.filters.model;
      }


      if (this.filters.color) {
        query.color = this.filters.color;
      }


      this.updateQueryParam(query)
    },
    updateQueryParam(updatedQuery) {
      const currentQuery = { ...this.$route.query };
      // Remove undefined,null values to avoid unnecessary query params
      Object.keys(updatedQuery).forEach((key) => {
        if (updatedQuery[key] === null || updatedQuery[key] === '' || updatedQuery[key] === undefined) {
          delete currentQuery[key];
        } else {
          currentQuery[key] = updatedQuery[key];
        }
      });


      this.$router.replace({ query: currentQuery });
    },
    // Format dates  without changing timezone 
    formatDateToString(date) {
      return `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(
        date.getDate()
      ).padStart(2, "0")}`
    }
  },
  async created() {
    this.$store.commit("search/setLoading", true);
    this.checkViewport();
    window.addEventListener("resize", this.checkViewport);
    if (this.$route.query.city) {
      // Set city from query params City, State
      await this.getCities();
      const cityParam = decodeURIComponent(this.$route.query.city);
      const [name, state] = cityParam.split(", ");
      const city = this.cities.find(
        (city) => city.name === name && city.state === state
      );
      await this.setCity(city);
    } else {
      await this.setDefaultCityFilter();
    }
    if (this.$route.query.pickupDate) {
      const localPickupDate = createLocalDate(this.$route.query.pickupDate);
      await this.setPickupDate(localPickupDate);
    }
    if (this.$route.query.returnDate) {
      const localReturnDate = createLocalDate(this.$route.query.returnDate);
      await this.setReturnDate(localReturnDate);
    }
    if (this.$route.query.model) {
      await this.toggleFilter({
        filter: "model",
        value: this.$route.query.model,
      });
    }
    if (this.$route.query.color) {
      await this.toggleFilter({
        filter: "color",
        value: this.$route.query.color,
      });
    }
    this.setQueryParams();
    this.searchVehicles();
    this.isInitialized = true;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkViewport);
  },
};
</script>


<style scoped>
#search-container {
  height: calc(100vh - 84px);
}
</style>
