<template>
  <div class="border-t">
    <!-- Footer Models -->
    <template v-if="$route.meta.footer == 'Search'">
      <div class="hidden md:grid grid-flow-row md:grid-flow-col gap-6 py-5 px-10 border-y border-resla-ebony-70">
        <template v-if="limitedModels.length > 0">
        <ModelLink v-for="model in limitedModels" :key="model.name" :imgSrc="model.public_image_link" :name="model.name"
          @click="handleModelLink(model)" />
      </template>
      <template v-else>
        <SkeletonLoader v-for="item in 4" :key="item" width="100%" height="203px" rounded></SkeletonLoader>
      </template>
      </div>
    </template>
    <!-- Footer Main (Default and Search) -->
    <footer v-if="$route.meta.footer == 'Default' || $route.meta.footer == 'Search'"
      class="flex flex-col lg:flex-row justify-between px-6 lg:px-10 mx-auto py-10 gap-10 lg:gap-20">
      <div class="w-full lg:max-w-[30rem] flex flex-col justify-between gap-y-12">
        <div class="logo">
          <router-link to="/" tag="a"><img src="/img/resla-logo-black-rgb-600px@300ppi.60cde18c.jpg" class="w-40 h-6"
              alt="resla logo black" /></router-link>
        </div>
        <div class="flex flex-col gap-4">
          <!--Social links-->
          <div class="flex items-center gap-3">
            <!--facebook link-->
            <a href="https://www.facebook.com/rentwithresla" target="_blank" class="size-6 p-0.5">
              <FacebookIcon></FacebookIcon>
            </a>
            <!--instagram link-->
            <a href="https://www.instagram.com/rentwithresla/" target="_blank" class="size-6 p-0.5">
              <InstagramIcon></InstagramIcon>
            </a>
            <!--youtube link-->
            <a href="https://www.youtube.com/channel/UCh6nstSu8JgJ0zg7CdnxZqg" target="_blank" class="size-6 p-0.5">
              <YoutubeIcon></YoutubeIcon>
            </a>
            <!--linkedin link-->
            <a href="https://www.linkedin.com/company/rentwithresla/" target="_blank" class="size-6 p-0.5">
              <LinkedinIcon></LinkedinIcon>
            </a>
          </div>
          <!--Privacy-->
          <div class="flex items-center gap-2 md:gap-3 text-xs md:text-sm text-resla-ebony-40 font-urbanist">
            <a href="mailto:support@resla.com">support@resla.com</a>

            |

            <a href="tel:+14804394079">(480) 439-4079</a>

            |

            <div>Resla © 2022-2025</div>
          </div>
        </div>
      </div>
      <!--Nav-->
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-8 w-full">
        <div v-for="nav in navigationLinks" :key="nav.title" class="flex flex-col gap-4 w-full">
          <div class="font-urbanist font-normal text-sm leading-[22.4px] text-resla-ebony-50">
            {{ nav.title }}
          </div>
          <CtaButton v-for="(link, index) in nav.links" :key="index" :to="link.path ? link.path : null"
            :url="link.url ? link.url : null" :target="link.url ? '_blank' : ''" variant="tertiary" class="w-max">
            {{ link.name }}
          </CtaButton>
        </div>
      </div>
    </footer>
    <!-- Footer Main (Application) -->
    <footer class="bg-white text-gray-700 py-6 pl-7 pr-7" v-if="$route.meta.footer == 'Application'">
      <div class="mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <div class="flex flex-wrap justify-center md:justify-start space-x-6">
          <router-link tag="a" to="/">Home</router-link>
          <a :href="`${landingBaseURL}/privacy`" target="_blank">Privacy</a>
          <a :href="`${landingBaseURL}/terms`" target="_blank">Terms</a>
          <a :href="`${landingBaseURL}/disclaimers`" target="_blank">Disclaimers</a>
          <a :href="`${landingBaseURL}/contact`" target="_blank">Contact</a>
        </div>

        <div class="flex items-center space-x-2">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg" alt="US Flag"
            class="w-5 h-3" />
          <select
            class="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-1">
            <option value="en-US">English (US)</option>
          </select>
          <div class="text-gray-500">
            <p>Resla © 2022-2025</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import api from '@/api';
import ModelLink from "@/components/core/ModelLink.vue";
import CtaButton from "@/components/core/CtaButton.vue";
import FacebookIcon from "../core/icons/FacebookIcon.vue";
import InstagramIcon from "../core/icons/InstagramIcon.vue";
import YoutubeIcon from "../core/icons/YoutubeIcon.vue";
import LinkedinIcon from "../core/icons/LinkedinIcon.vue";
import SkeletonLoader from '../core/animations/SkeletonLoader.vue';

export default {
  name: "FooterMainSection",
  components: {
    ModelLink,
    CtaButton,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkedinIcon,
    SkeletonLoader
  },
  data() {
    return {
      landingBaseURL: process.env.VUE_APP_LANDING_ROOT_URL,
      navigationLinks: [],
      models: [
      ],
    };
  },
  computed:{
    limitedModels() {
            if (this.models) {
                return this.models
                    .filter(vehicle => vehicle.name === 'Model 3' || vehicle.name === 'Model Y' || vehicle.name === 'Model S' || vehicle.name === 'Model X')
            }
            return null
        },
  },
  methods: {
    handleModelLink(model) {
            if (this.$route.name !== 'search') {
                this.$router.push(`/search?model=${model.name}`);
            } else {
                this.toggleFilter({ filter: 'model', value: model.name });
            }
        },
  },
  created() {
    api.vehicleModels.getVehicleModels().then((models) => {
      this.models = models;
    });
    this.navigationLinks = [
      {
        title: "Account",
        links: [
          { name: "Home", path: "/" },
          { name: "My Trips", path: "/trips" },
          { name: "Book Now", path: "/search" },
        ],
      },
      {
        title: "Company",
        links: [
          { name: "Careers", url: `${this.landingBaseURL}/careers` },
          { name: "Contact", url: `${this.landingBaseURL}/contact` },
        ],
      },
      {
        title: "Resources",
        links: [
          { name: "Tutorials", url: `${this.landingBaseURL}/tutorials` },
          { name: "Blog", url: `${this.landingBaseURL}/blog` },
          { name: "FAQ", url: `${this.landingBaseURL}/faqs` },
        ],
      },
      {
        title: "Legal",
        links: [
          { name: "Policies", url: `${this.landingBaseURL}/policies` },
          { name: "Terms of Use", url: `${this.landingBaseURL}/terms` },
          { name: "Privacy", url: `${this.landingBaseURL}/privacy` },
          {
            name: "Rental Agreement",
            url: `${this.landingBaseURL}/rental-agreement`,
          },
        ],
      },
    ];
  },
};
</script>
