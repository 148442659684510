<template>
  <div class="flex flex-col w-full mx-auto max-w-[42.5rem] md:px-0 px-6 py-6 md:py-24 gap-y-8">
    <!--header-->
    <div class="flex flex-col md:flex-row gap-5 md:items-center justify-between">
      <div>
        <CtaButton variant="tertiary" leading-icon :to="'/trip/' + $route.params.platform_id" class="w-max">
          My Trip
        </CtaButton>
        <h2 class="text-heading tracking-negative-3 text-primary-100 font-medium mt-3">
          Your Resla Receipts
        </h2>
      </div>
      <div class="flex items-center place-items-end gap-5">
        <CtaButton variant="tertiary" @click="handlePoliciesClick">
          Policies
          <ArrowUpRight slot="trailing" class="size-4" />
        </CtaButton>
        <CtaButton variant="tertiary" @click="handlePrint">
          Print
          <PrinterIcon slot="trailing" class="size-5" />
        </CtaButton>
      </div>
    </div>
    <template v-if="pageLoading">
      <div class="flex flex-col md:hidden">
        <SkeletonLoader width="100%" height="130px" rounded border-radius="8px" />
      </div>
      <div class="flex items-center gap-3">
        <SkeletonLoader width="112px" height="80px" rounded border-radius="8px" />
        <div class="flex w-full justify-between">
          <div>
            <SkeletonLoader width="90px" height="18px" rounded border-radius="4px" />
            <SkeletonLoader width="140px" height="16px" rounded border-radius="4px" class="mt-2" />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <SkeletonLoader width="100%" height="66px" rounded border-radius="8px" />
        <div class="flex flex-col gap-6">
          <div class="">
            <SkeletonLoader width="150px" height="16px" rounded border-radius="4px" class="mt-2" />
            <SkeletonLoader width="190px" height="12px" rounded border-radius="4px" class="mt-2" />
          </div>
          <div class="">
            <SkeletonLoader width="150px" height="16px" rounded border-radius="4px" class="mt-2" />
            <SkeletonLoader width="190px" height="12px" rounded border-radius="4px" class="mt-2" />
          </div>
          <div class="">
            <SkeletonLoader width="100px" height="16px" rounded border-radius="4px" class="mt-2" />
            <SkeletonLoader width="140px" height="12px" rounded border-radius="4px" class="mt-2" />
          </div>
          <div class="">
            <SkeletonLoader width="150px" height="16px" rounded border-radius="4px" class="mt-2" />
            <SkeletonLoader width="190px" height="12px" rounded border-radius="4px" class="mt-2" />
          </div>

        </div>
      </div>
    </template>
    <template v-else-if="reservation && !pageLoading">
      <!-- Vehicle Info -->
      <div class="flex flex-col md:hidden bg-primary-900 border border-primary-700 p-6 gap-2 rounded-lg">
        <p class="font-urbanist text-base leading-6.5 text-primary-100">Total</p>
        <p class="text-heading tracking-negative-3 text-primary-100 font-medium mt-2">
          ${{ totalPrice / 100 }}
        </p>
      </div>
      <div class="flex items-center gap-3">
        <img :src="getMoneyshotImage(reservation.vehicle.images)" class="w-28 h-20 object-cover rounded-xl" />
        <div class="flex-1">
          <h3 class="text-2xl leading-9 tracking-negative-2 font-medium text-primary-100">
            {{ reservation.vehicle.model.name }}
          </h3>
          <p class="font-urbanist text-base leading-6.5 text-primary-100">
            {{ reservation.vehicle.vin }}
          </p>
          <p class="font-urbanist text-base leading-6.5 text-primary-100">
            {{ formatDateToLocale(reservation.pickup_at) }} -
            {{ formatDateToLocale(reservation.return_at) }}
          </p>
        </div>
        <div class="hidden md:block w-px min-h-20 bg-primary-700"></div>
        <div class="hidden md:block pr-2">
          <p class="font-urbanist text-base leading-6.5 text-primary-100">
            Total
          </p>
          <p class="text-3xl leading-8 tracking-negative-3 text-primary-100 font-medium mt-2">
            ${{ totalPrice / 100 }}
          </p>
        </div>
      </div>
      <ReceiptCard
        v-for="(transaction, key, index) in sortedReceipt"
        :key="key"
        :open="index === 0"
        :receipt-id="`Receipt #${reservation.id.split('-')[1]}-${String(index + 1).padStart(3, '0')}`"
        :transactions="transaction"
        :timezone="reservation.pickup_location.timezone"
        :date="key"
        :payment-method="reservation.payment_method" :card-digit="reservation.card_last_four">
      </ReceiptCard>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { formatDateToLocale, formatTimeToLocale } from '@/helpers/util';
import ReceiptCard from '@/components/cards/ReceiptCard.vue';
import CtaButton from '@/components/core/CtaButton.vue';
import PrinterIcon from '@/components/core/icons/PrinterIcon.vue';
import ArrowUpRight from '@/components/core/icons/ArrowUpRight.vue';
import SkeletonLoader from '@/components/core/animations/SkeletonLoader.vue';

export default {
  name: 'TripReceiptView',
  components: {
    CtaButton,
    ArrowUpRight,
    PrinterIcon,
    ReceiptCard,
    SkeletonLoader
  },
  data() {
    return {
      pageLoading: false
    };
  },
  computed: {
    ...mapState('reservations', ['reservations']),
    reservation() {
      return this.reservations.find(
        (reservation) => reservation.id === this.$route.params.platform_id
      );
    },
    totalDiscount() {
      return this.reservation.transactions.reduce((sum, transaction) => {
        return sum + (transaction.discount_amount || 0);
      }, 0);
    },
    totalPrice() {
      const totalCaptured = this.reservation?.transactions.reduce(
        (acc, transaction) =>
          acc + transaction.captured_amount,
        0
      );
      return totalCaptured;
    },
    transactionGrouped() {
      return !this.reservation ? [] : this.reservation.transactions.reduce((acc, item) => {
        if (item.category === 'Tax' && item.captured_amount === 0) {
          return acc;
        }
        
        if (item.category === 'Deposit' && item.status === 'Failed') {
          return acc;
        }

        const key = item.initiated_at;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        acc[key].sort((a, b) => {
          if (a.category === 'Rack') return -1;
          if (b.category === 'Rack') return 1;
          if (a.category === 'Addon') return -1;
          if (b.category === 'Addon') return 1;
          if (a.category === 'Additional') return -1;
          if (b.category === 'Additional') return 1;
          if (a.category === 'Deposit') return -1;
          if (b.category === 'Deposit') return 1;
          return 0;
        });
        return acc;
      }, {});
    },
    sortedReceipt() {
      const groupedTransactions = this.transactionGrouped;
      return Object.keys(groupedTransactions)
        .sort((a, b) => new Date(a) - new Date(b))
        .reduce((acc, key) => {
          acc[key] = groupedTransactions[key];
          return acc;
        }, {});
    }
  },
  methods: {
    ...mapActions('reservations', ['getReservations']),
    handleBackButton() {
      this.$router.push({
        name: 'trip',
        params: { platform_id: this.$route.params.platform_id }
      });
    },
    handlePrint() {
      window.print();
    },
    handlePoliciesClick() {
      window.open(`${process.env.VUE_APP_LANDING_ROOT_URL}/policies`, '_blank');
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')
          ?.image_url;
    },
    formatDateToLocale,
    formatTimeToLocale
  },
  async created() {
    this.pageLoading = true;
    try {
      await this.getReservations();
    } catch (error) {
      console.error('Error fetching reservations on receipt page', error);
    } finally {
      this.pageLoading = false;
    }
  }
};
</script>

<style scoped></style>
