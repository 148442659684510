const userService = (api) => ({
  getUser: async () => {
    return api.request({
      method: 'get',
      url: '/api/v1/users'
    });
  },
  updateProfile: async (data) => {
    return api.request({
      method: 'PATCH',
      url: '/api/v1/users/profile',
      data
    });
  },
});

export default userService;
